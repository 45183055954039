.order {
  .ant-row.order__container {
    flex-direction: column;
    align-content: center;
  }
  &__container {
    color: $secondary-black;
    margin: 24px 0;

    .ant-divider {
      border-top-color: $primary-grey;
    }

    .ant-form-item-label {
      font-weight: 600;
      padding-bottom: 0;
    }

    a.ant-typography {
      color: $black;

      &:hover {
        color: $secondary-green;
      }
    }
  }

  &__header {
    h2.ant-typography {
      margin-bottom: 0;
    }

    &-icon {
      margin-right: 32px;
    }
  }

  &__breadcrumb {
    padding: 0 0 20px;

    li {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ant-breadcrumb-link {
      cursor: pointer;

      &:hover {
        color: $secondary-green;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      font-weight: bold;
      margin: 0 22px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    color: $secondary-black;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    h3.ant-typography {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    h3.ant-typography {
      margin-bottom: 32px;
    }
  }

  &__info {
    margin-right: 5px;

    &-small {
      font-size: 12px;
    }

    &-large {
      font-size: 16px;
    }

    &-xxl {
      font-size: 18px;
    }

    &-empty {
      margin-top: 24px;
    }

    &-address {
      width: 100%;

      svg {
        width: 14px;
        height: auto;
        color: $secondary-green;
      }
    }

    &-icon {
      margin-right: 10px;
    }
  }

  &__info-content {
    .ant-btn:hover,
    .ant-btn:focus {
      border-color: transparent;
      color: $secondary-green;
    }
  }

  &__contract {
    &-text {
      margin-top: 16px;
    }
  }

  &__form {
    .ant-select-dropdown,
    .ant-picker-dropdown {
      z-index: 499;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $secondary-green;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: $secondary-green;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: $secondary-green;
    }

    .ant-form-item {
      label {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .ant-input,
    .ant-input-number-lg {
      font-size: 14px;
    }
    .ant-input-number-lg {
      width: 100%;
    }
    .ant-select-lg,
    .ant-picker-large .ant-picker-input > input {
      font-size: 14px;
    }

    .ant-input-affix-wrapper {
      font-size: 14px;
    }
    .input__phone {
      svg {
        color: $secondary-grey;
      }
    }
    .ant-card {
      margin-bottom: 32px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .ant-upload.ant-upload-select {
      width: 100%;
      .ant-btn-lg {
        width: 100%;
        height: auto;
        padding: 11px 0;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: 49px;
      border: none;
    }
    .ant-alert {
      padding: 12px 16px;
    }
    .ant-alert-icon {
      margin-right: 10px;
    }

    @media (min-width: 1620px) {
      .ant-form-item label,
      .ant-input,
      .ant-input-number-lg,
      .ant-select-lg,
      .ant-picker-large .ant-picker-input > input,
      .ant-input-affix-wrapper {
        font-size: 16px;
      }
    }

    @media (max-width: 476px) {
      .ant-cascader-menu-item,
      .ant-cascader-menu-item-content {
        white-space: break-spaces;
      }
    }
  }

  &__upload-photo {
    button.ant-btn {
      padding: 0;
    }
  }

  &__card {
    margin-bottom: 32px;
    h4.ant-typography {
      margin-bottom: 0;
    }

    .ant-collapse {
      background-color: $white;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: $white;
      padding: 0;
      font-size: 16px;
      text-decoration: underline;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
      flex: auto;
      width: 95%;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }

  &__tabs {
    .ant-tabs-tab:hover,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $secondary-green;
    }

    .ant-tabs-ink-bar {
      background-color: $secondary-green;
    }

    .ant-card {
      margin-top: 16px;
    }

    .ant-alert {
      padding: 16px 24px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &__badge {
    .ant-badge-count {
      background: $secondary-green;
      margin-left: 5px;
      box-shadow: none;
    }

    &-grey {
      .ant-badge-count {
        color: $secondary-black;
        background: $primary-grey;
        margin-left: 5px;
        box-shadow: none;
      }
    }
  }

  &__adresses {
    .ant-space,
    .ant-space-item {
      width: 100%;
    }
  }

  &__dimensions {
    .ant-form-item {
      margin-bottom: 0;
    }
    @media (max-width: 680px) {
      .ant-space {
        flex-wrap: wrap;
      }
      .ant-space-item {
        width: 100%;
      }
    }
  }

  &__transport {
    width: 100%;
    .ant-row {
      width: 100%;
    }

    &-hidden {
      display: none;
    }

    &-trailer,
    &-truck {
      height: 88px;
    }

    &-trailer {
      width: 383px;
    }
    &-truck {
      width: 278px;
    }

    @media (max-width: 1150px) {
      .ant-row {
        flex-direction: column;
      }
    }

    @media (max-width: 530px) {
      &-trailer,
      &-truck {
        height: 75px;
        width: auto;
      }
    }

    @media (max-width: 450px) {
      &-trailer,
      &-truck {
        height: 70px;
        width: auto;
      }
    }

    @media (max-width: 400px) {
      &-trailer,
      &-truck {
        height: 50px;
        width: auto;
      }
    }
  }

  &__space-full-width {
    width: 100%;
    justify-content: space-between;
  }

  &__two-column {
    .ant-space-item {
      flex-basis: 100%;

      .ant-picker {
        width: 100%;
      }
    }

    @media (max-width: 516px) {
      flex-direction: column;
      .ant-space-item {
        width: 100%;
      }
    }
  }

  &__warning-message {
    margin-top: 24px;
    color: $black;

    h5 {
      display: flex;
      align-content: center;
      align-items: center;

      svg {
        margin-right: 10px;
        color: $warning;
      }
    }
  }

  &__cost {
    padding: 20px 0 0;

    @media (max-width: 516px) {
      .ant-row {
        flex-direction: column;
      }
      .ant-space-item {
        width: 100%;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    .ant-btn-lg {
      width: 100%;
      height: auto;
      padding: 16px 0;
      line-height: 24px;
    }

    &-description {
      font-size: 16px;
      @media (max-width: 1440px) {
        font-size: 14px;
      }
    }

    @media (max-width: 576px) {
      .ant-btn {
        white-space: pre-wrap;
        height: auto;
      }
    }
  }

  &__total {
    font-size: 20px;
    .ant-typography {
      word-break: inherit;
    }
    &-distance {
      width: 100%;
      margin-bottom: 16px;
    }
    &-disabled {
      opacity: 0.5;
    }

    @media (max-width: 575px) {
      text-align: left;
    }
  }

  &__attachments {
    .ant-upload-list-item {
      font-size: 16px;
    }

    .ant-upload-list-item-info {
      width: fit-content;
      margin: 16px 0 0;
    }

    .ant-upload-list-text .ant-upload-list-item-name {
      margin-right: 32px;
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background: transparent;
    }

    .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }

    .ant-form-item-extra {
      margin-top: 12px;
    }

    &-row {
      display: flex;
      padding: 12px 0 0;
      width: 100%;
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    &-doc {
      font-size: 16px;
    }

    &-upload {
      margin-right: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-remove {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-btns {
      font-size: 24px;
      margin-left: auto;

      a {
        margin-left: 15px;
      }
    }
  }

  &__bottom-btns {
    width: 100%;
    margin-bottom: 90px;
    margin-top: 20px;

    .ant-form-item-control-input-content {
      display: flex;
      flex-wrap: nowrap;
    }
    .ant-btn {
      width: 100%;
      height: auto;
      padding: 16px 0;
    }

    @media (max-width: 366px) {
      .ant-btn {
        margin-right: 0;
      }
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
      }
    }
  }

  &__btns {
    &-wrapper-left {
      width: 33%;
      padding-right: 12px;
    }
    &-wrapper-rigth {
      width: 33%;
      padding-left: 12px;
      .ant-tooltip-disabled-compatible-wrapper {
        width: 100%;
      }
    }
    &-wrapper-middle {
      width: 34%;
    }
    &-cancel {
      margin-right: 24px;
    }
    @media (max-width: 366px) {
      &-wrapper-left,
      &-wrapper-rigth {
        width: 100%;
        padding: 0;
      }
      &-cancel {
        margin-right: 0;
      }
    }

    @media (min-width: 1620px) {
      &-wrapper-left,
      &-wrapper-rigth {
        .ant-btn {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  &__image {
    width: 100px;
    height: auto;
  }
}

.ant-table-filter-dropdown {
  input {
    height: 44px;
  }

  .ant-btn {
    height: 44px;
    border-radius: 4px;
  }
}
