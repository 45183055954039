.tabs {
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $secondary-green;
  }

  .ant-tabs-ink-bar {
    background-color: $secondary-green;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-alert {
    padding: 16px 24px;
  }
  .ant-alert-icon {
    margin-right: 16px;

    svg {
      width: 20px;
      height: auto;
    }
  }
}
