.rate {
  &__breadcrumb {
    padding: 30px 0 0 0 !important;

    .ant-breadcrumb-link {
      cursor: pointer;

      &:hover {
        color: $secondary-green;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      font-weight: bold;
      margin: 0 22px;
    }
  }

  &__info {
    margin-right: 5px;

    &-small {
      font-size: 12px;
    }

    &-large {
      font-size: 16px;
    }

    &-xxl {
      font-size: 18px;
    }

    &-empty {
      margin-top: 24px;
    }
  }
}
