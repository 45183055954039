.offer {
  width: 100%;

  .ant-divider {
    margin: 0;
  }

  .ant-steps-item-title {
    font-size: 20px;
  }

  .ant-card-body {
    padding: 16px 24px;
  }

  h4.ant-typography {
    margin-bottom: 0;
  }

  &__content-wrap {
    margin-top: 32px;

    h4.ant-typography {
      margin-bottom: 0;
    }

    .ant-alert {
      padding: 16px 24px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    h4.ant-typography {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-weight: 400;
    display: block;
  }

  &__card {
    &-title {
      font-size: 14px;
      .ant-avatar {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__info {
    margin-bottom: 24px;

    &-large {
      font-size: 16px;
    }

    &-xxl {
      font-size: 18px;
    }

    &-last {
      margin-bottom: 32px;
    }
  }

  &__comment {
    margin-top: 24px;
  }

  &__actions {
    margin-top: 32px;
  }

  &__text {
    font-size: 16px;
  }

  &__file {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      svg {
        height: 20px;
        width: auto;
      }
    }
  }

  &__text-btn {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: $secondary-green;
    }
  }

  &__space-full-width {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .ant-space-item {
      flex-basis: 100%;

      .ant-picker {
        width: 100%;
      }
    }
    @media (max-width: 576px) {
      .ant-space {
        flex-wrap: wrap;
        gap: 24px;
      }
    }
  }
  &__new-price {
    background: #00bc41;
    border: 0.1875em solid #00bc41;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  &__periods {
    margin-bottom: 24px;
    @media (max-width: 1326px) {
      flex-direction: column;
      .ant-space-item {
        width: 100%;
      }
    }
    @media (max-width: 992px) {
      flex-direction: row;
    }
    @media (max-width: 817px) {
      flex-direction: column;
    }
  }

  &__full-width {
    width: 100%;

    .ant-space {
      width: 100%;
    }
    .ant-alert {
      padding: 16px 24px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &__files-history {
    .ant-divider {
      margin: 0;
      border-top-color: $primary-grey;
    }
    .ant-card-body {
      padding: 16px 24px;
    }
  }

  &__bottom-btns {
    .ant-btn:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__message {
    .ant-card-body {
      padding: 24px 0;
      background-color: $light-yellow;
      border: 1px solid $yellow;
    }

    &-text {
      margin-bottom: 16px;
    }
  }

  &__icon {
    &-requested {
      margin-top: 2px;
      svg {
        width: 18px;
        height: 20px;
      }
    }
    &-declined {
      svg {
        width: 20px;
        height: 20px;
        padding-top: 4px;
      }
    }
  }

  &__declined {
    opacity: 0.75;
    .ant-typography strong {
      color: $disabled-grey;
    }
  }

  &__blocked {
    &-ribbon {
      .ant-ribbon.ant-ribbon-placement-end {
        top: 16px;
        @media (max-width: 451px) {
          height: fit-content;
          width: 50%;
          white-space: normal;
        }
      }
    }
    &-icon {
      svg {
        color: $error;
      }
    }
  }

  &__footer {
    .ant-space {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 785px), (min-width: 992px) and (max-width: 1275px) {
    .ant-steps {
      display: flex;
      flex-direction: column;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
      padding-left: 16px;
    }
    .ant-steps-item-container {
      display: flex;
      .ant-steps-item-content {
        display: block;
        min-height: 48px;
        overflow: hidden;
      }
    }
  }
}
