.form {
  .ant-form-item-label > label {
    color: $dark-grey;
    font-weight: 700;
  }

  .ant-form-item {
    font-size: 16px;
    .ant-tag-processing {
      font-weight: 400;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ant-input-number-affix-wrapper,
  .ant-input-number {
    width: 100%;
  }

  a.ant-typography {
    color: $black;
  }

  &__comment {
    height: 100px;

    .ant-input {
      padding: 16px;
      font-size: 16px;
    }

    @media (min-width: 1620px) {
      .ant-input {
        font-size: 16px;
        line-height: 24px;
        padding: 16px;
      }
    }
  }

  &__upload-file {
    .ant-upload-list-item {
      font-size: 16px;
    }

    .ant-upload-list-item-info {
      width: fit-content;
      margin: 16px 0 0;
    }

    .ant-upload-list-text .ant-upload-list-item-name {
      margin-right: 32px;
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background: transparent;
    }

    .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }

    .ant-form-item-extra {
      margin-top: 12px;
    }
  }

  &__upload-icon {
    margin-right: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__remove-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__date-picker {
    .ant-picker {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ant-card-body {
      padding: 11px;
    }

    &-text {
      font-size: 14px;
    }
  }

  &__full-width {
    width: 100%;
    .ant-space-item {
      width: 100%;
    }
  }

  &__align-right {
    text-align: right;

    svg {
      width: 14px;
      height: auto;
      color: $secondary-green;
    }
  }

  &__edit-link {
    .ant-form {
      margin-bottom: 24px;
    }
  }
}
