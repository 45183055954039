@mixin flex($vertical: false, $horizontal: false) {
  display: flex;

  @if $vertical {
    align-items: $vertical;
  }

  @if $horizontal {
    justify-content: $horizontal;
  }
}
