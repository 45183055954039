:root {
  --indent: 104px;
  --auth-indent: 105px;
}

@media screen and (max-width: 1279px) {
  :root {
    --indent: 74px;
    --auth-indent: 129px;
  }
}

@media screen and (max-width: 575px) {
  :root {
    --indent: 58px;
    --auth-indent: 105px;
  }
}
