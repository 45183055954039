.orders__tabs-table {
  .ant-tabs > .ant-tabs-nav {
    padding: 0 15px;
    margin: 0;
    border: 1px solid $grey;
    border-bottom: none;
    background-color: $white;
    height: 82px;
  }

  .ant-empty {
    margin: 32px 8px;
  }

  .ant-empty-image {
    margin-bottom: 32px;
  }

  .order__info {
    font-size: 14px;
  }
}

.contracts__tabs-table {
  .ant-tabs > .ant-tabs-nav {
    padding: 0 15px;
    margin: 0;
    border: 1px solid $grey;
    border-bottom: none;
    background-color: $white;
    height: 82px;
  }

  .ant-empty {
    margin: 32px 8px;
  }

  .ant-empty-image {
    margin-bottom: 32px;
  }

  .contract__info {
    font-size: 14px;
  }
}

.organizations__tabs-table {
  .ant-tabs > .ant-tabs-nav {
    padding: 0 15px;
    margin: 0;
    border: 1px solid $grey;
    border-bottom: none;
    background-color: $white;
    height: 82px;
  }

  .ant-empty {
    margin: 32px 8px;
  }

  .ant-empty-image {
    margin-bottom: 32px;
  }
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 5px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 999;

  &:last-of-type {
    right: 0;
  }
}

.table__orders,
.table__contracts,
.table__organizations,
.table__rates {
  margin-bottom: 32px;

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:first-of-type,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:first-of-type {
    border-left: 1px solid $primary-grey;
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-of-type,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-of-type {
    border-right: 1px solid $primary-grey;
  }

  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: $light-grey;
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: $primary-grey;
  }

  .ant-table-filter-trigger {
    margin: 0;
  }

  .ant-checkbox-inner {
    border-color: $dark-grey;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $dark-grey;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $dark-grey;
  }

  .ant-table-thead > tr > th {
    background-color: $white;
    border-collapse: collapse;
    border-top: 1px solid $primary-grey;
    border-bottom: 1px solid $primary-grey;
    border-collapse: collapse;
    font-weight: bold;
    padding: 10px 16px;
  }

  .ant-table-tbody > tr > td {
    padding: 6px 16px;
    border-bottom: 1px solid $primary-grey;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-table-pagination {
    border: 1px solid $primary-grey;
    border-top-width: 0;
    background-color: $white;
    margin: 0;
    padding: 10px 15px;
    height: 65px;
    align-items: center;
  }

  a.ant-typography {
    color: $secondary-black;
  }

  .ant-btn,
  a.ant-btn {
    line-height: 30px;
    color: #00bc41;
    height: auto;

    &.ant-btn-dangerous {
      color: $error;
    }
  }

  .ant-btn-default:hover,
  .ant-btn-default:focus,
  .ant-btn-default:active {
    color: #fff;
    background-color: #00bc41;
  }
}

.table__orders-btn {
  .ant-btn {
    color: $white;
  }
}
