.login {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 10px 0;
  &__card {
    background: $white;
    width: 650px;
    border: 1px solid $light-grey;
    padding: 10px 20px 30px 20px;
    margin: 0;
    @media (min-width: 778px) {
      padding: 20px 40px 30px 40px;
    }
    @media (max-width: 778px) {
      width: fit-content;
      margin: 0 64px;
      border: none;
    }
    @media (max-width: 576px) {
      margin: 0 24px;
    }
    &__header {
      margin-top: 20px;
      margin-bottom: 16px;
      .ant-typography {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: 576px) {
          font-size: 16px;
        }
      }

    }
    &__checkbox {
      position: relative;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &__link a.ant-typography{
      color: $primary-green;
    }
    &__settings {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__footer {
      .ant-space,
      .ant-space-item:not(.ant-space-item:last-of-type) {
        width: 100%;
        .ant-space {
          width: fit-content;
        }
      }
      .ant-typography {
        word-break: normal;
      }
      .ant-alert {
        padding: 12px 16px;
        width: 100%;
      }
      .ant-alert-icon {
        margin-right: 10px;
      }

    }
    &__button.ant-btn-primary {
      width: 100%;
      height: auto;
      padding: 12px 128px;

      @media (max-width: 510px) {
        padding: 12px 0;
      }
    }
  }
  & .ant-form, & .ant-form label{
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }
}