.organizations {
  &__badge {
    .ant-badge-count {
      background: $secondary-green;
      margin-left: 5px;
      box-shadow: none;
    }

    &-grey {
      .ant-badge-count {
        color: $secondary-black;
        background: $primary-grey;
        margin-left: 5px;
        box-shadow: none;
      }
    }
  }

  &__info {
    margin-right: 5px;

    &-small {
      font-size: 12px;
    }

    &-large {
      font-size: 16px;
    }

    &-xxl {
      font-size: 18px;
    }

    &-empty {
      margin-top: 24px;
    }

    &-address {
      width: 100%;

      svg {
        width: 14px;
        height: auto;
        color: $secondary-green;
      }
    }

    &-icon {
      margin-right: 10px;
    }
  }
}
