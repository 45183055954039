.operator {
  margin-bottom: 32px;

  a.ant-typography {
    color: $black;

    &:hover {
      color: $secondary-green;
    }
  }
  h4.ant-typography,
  h3.ant-typography {
    white-space: normal;
    margin-bottom: 0;
  }

  &__header {
    h3.ant-typography,
    h2.ant-typography {
      margin-bottom: 0;
    }
  }

  &__breadcrumb {
    padding: 32px 0 20px;
    .ant-breadcrumb-link {
      cursor: pointer;
      &:hover {
        color: $secondary-green;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      font-weight: bold;
      margin: 0 22px;
    }
  }

  &__full-width {
    width: 100%;
    .ant-space {
      display: flex;
    }
    .ant-space-item {
      width: 100%;
    }
  }

  &__main-col {
    .ant-card-head {
      padding: 0 56px;
    }
    .ant-card-head-title {
      padding: 40px 0;
    }
    .ant-card-body {
      padding: 40px 56px;
    }

    @media (max-width: 446px) {
      .ant-card-head {
        padding: 0 24px;
      }
      .ant-card-head-title {
        padding: 24x 0;
      }
      .ant-card-body {
        padding: 24px;
      }
    }
  }

  &__action-col {
    .ant-card-head {
      padding: 0 32px;
    }
    .ant-card-head-title {
      padding: 20px 0;
    }
    .ant-card-body {
      padding: 20px 32px;
      .ant-space {
        width: 100%;
      }
    }
    .ant-btn-lg {
      padding: 12px;
      width: 100%;
      height: auto;
      white-space: normal;
    }
  }

  &__claim {
    .ant-modal-footer {
      text-align: inherit;
    }
    &-footer {
      display: flex;
      flex-direction: column;
      gap: 24px;

      button {
        width: min-content;
        align-self: flex-end;
      }
    }
  }

  &__ati {
    &-raiting {
      display: inline-flex;
      align-items: center;
      gap: 12px;

      h4.ant-typography {
        margin-bottom: 0;
        font-size: 18px;
        white-space: normal;
      }
      .ant-btn {
        padding: 8px;
        line-height: 1.5715;
        height: auto;
        font-size: 16px;
      }
    }
    &-point {
      border-radius: 2px;
      padding: 2px 8px;
      text-align: center;
      font-weight: 600;
      background-color: $light-grey;
    }
    &-btn {
      width: 100%;
    }
  }
}
