.header {
  position: relative;

  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    .ant-badge {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.7;
    }
  }

  a,
  button {
    cursor: pointer;
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }

    @media (hover) {
      &:hover {
        outline: none;
      }
    }
  }

  ul {
    list-style: none;
  }

  a:hover {
    text-decoration: none;
  }

  .header__container {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background: $white;
    box-shadow: 0px 4px 4px rgba($black, 0.06);
  }

  .header__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1792px;
    padding: 0 16px;
    margin: 0 auto;
  }

  .logo {
    flex-shrink: 0;
    max-width: 154px;
    margin-right: 70px;

    a {
      display: block;

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  .header__nav {
    width: 100%;
    margin-right: 30px;
    letter-spacing: -0.5px;

    .header__nav-icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      height: 22px;

      img {
        vertical-align: top;
      }
    }

    .header__nav-list {
      display: flex;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      grid-gap: 40px;

      .header__nav-item {
        a {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          grid-gap: 9px;
          height: 100%;
          padding: 39px 0 41px;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2.4rem;
          color: #222;

          .active {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $secondary-green;
          }

          @media (hover) {
            &:hover {
              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: $secondary-green;
              }
            }
          }

          @media screen and (max-width: 767px) {
            .ant-badge {
              display: none;
            }
          }
        }

        &.active {
          a {
            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: $secondary-green;
            }
          }
        }
      }
    }
  }

  .header__auth {
    @include flex;
    grid-gap: 16px;
    margin-left: auto;
    margin-bottom: 4px;

    .header__auth-link {
      display: block;
      padding: 8px 32px;
      border: 1px solid $secondary-green;
      border-radius: 2px;
      font-size: 1.6rem;
      line-height: 2.4rem;

      &.header__register {
        background-color: $white;
      }

      &.header__login {
        background-color: $secondary-green;
        font-weight: 600;
        color: $white;
      }

      @media (hover) {
        &:hover {
          border-color: $secondary-black;
          background-color: $secondary-black;

          &.header__register {
            color: $white;
          }
        }
      }
    }
  }

  .header__user {
    position: relative;
    @include flex(center);
    flex-shrink: 0;
    grid-gap: 12px;
    margin-left: auto;
    margin-bottom: 4px;

    .header__user-icon {
      @include flex(center, center);
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid $secondary-green;
      background-color: $secondary-green;

      svg {
        fill: $white;
      }
    }

    &.has-icon {
      .header__user-icon {
        border-color: $primary-grey;
        background-color: $white;
      }
    }

    .header__user-name {
      max-width: 180px;
      max-height: 2.4rem;

      span {
        display: inline-block;
        max-width: 180px;
        font-size: 1.6rem;
        line-height: 2.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .header__user-toggle {
      flex-shrink: 0;
      cursor: pointer;

      svg {
        stroke: $dark-grey;
      }

      @media (hover) {
        &:hover {
          svg {
            stroke: $secondary-green;
          }
        }
      }
    }

    .header__user-menu {
      position: absolute;
      top: calc(100% + 46.5px);
      right: 0;
      width: max-content;
      padding: 30px;
      box-shadow: 0px 0px 16px $primary-grey;
      border-radius: 8px;
      background-color: $white;
      visibility: hidden;
      opacity: 0;

      &.active {
        visibility: visible;
        opacity: 1;
      }

      .header__user-menu-list {
        .header__user-menu-item {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0 !important;
          }

          a {
            @include flex(center);
            grid-gap: 16px;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 500;
            color: $dark-grey;

            svg {
              flex-shrink: 0;
              fill: $secondary-black;
            }

            &.active {
              svg {
                fill: $secondary-green;
              }
            }

            @media (hover) {
              &:hover {
                svg {
                  fill: $secondary-green;
                }
              }
            }
          }
        }
      }
    }
  }

  .header__menu-toggle {
    @include flex(center, center);
    flex-shrink: 0;
    display: none;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: 32px;
    border: 0;
    background: none;

    svg {
      stroke: $secondary-green;

      &.close {
        display: none;
      }
    }

    &.active {
      svg {
        &.open {
          display: none;
        }

        &.close {
          display: block;
        }
      }
    }

    @media (hover) {
      &:hover {
        svg {
          stroke: $secondary-black;
        }
      }
    }
  }

  &--is-auth {
    .header__nav {
      .header__nav-list {
        grid-gap: 39px;

        .header__nav-item {
          a {
            padding: 14px 0 17px;
          }
        }

        &:first-child {
          grid-gap: 35px;
          border-bottom: 1px solid $grey;

          .header__nav-item {
            a {
              padding: 14px 0 11px;
              font-size: 1.4rem;
              font-weight: 400;
              color: rgba($secondary-black, 0.6);

              @media (hover) {
                &:hover {
                  &:after {
                    bottom: -1px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.scrolled {
      .header__nav {
        .header__nav-list {
          grid-gap: 40px;

          .header__nav-item {
            a {
              padding: 39px 0 41px;
            }
          }

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1680px) {
    .header__wrapper {
      max-width: 1280px;
    }

    .logo {
      margin-right: 54px;
    }

    &--is-auth {
      .header__nav {
        width: initial;
        margin-right: 20px;

        .header__nav-list {
          grid-gap: 25px;
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .header__wrapper {
      max-width: 1199px;
    }

    .logo {
      max-width: 140px;
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1279px) {
    .header__wrapper {
      flex-wrap: wrap;
      max-width: 991px;
    }

    .logo {
      max-width: 133px;
      margin-right: 10px;
    }

    .header__nav {
      display: none;
    }

    .header__auth {
      padding: 16px 0;
      margin-bottom: 0;
    }

    .header__user {
      position: initial;
      padding: 16px 0;
      margin-bottom: 0;

      .header__user-icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      .header__user-name,
      .header__user-toggle {
        display: none;
      }

      .header__user-toggle {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
      }

      .header__user-menu {
        position: absolute;
        top: 73px;
        right: 0;
        @include flex(center);
        flex-direction: column;
        width: 100%;
        border-radius: 0;
        padding: 16px 16px 26px;
        box-shadow: 0px 4px 4px rgba($black, 0.03);

        .header__user-menu-list {
          width: 100%;
          max-width: 959px;

          .header__user-menu-item {
            margin-bottom: 16px;
          }
        }
      }

      @media (hover) {
        &:hover {
          .header__user-icon {
            background-color: $secondary-black;
          }
        }
      }
    }

    .header__menu-toggle {
      display: flex;
    }

    &--is-auth {
      .header__nav {
        display: block;
        width: 100%;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-right: 0;

        .header__nav-list {
          grid-gap: 40px;
          border-top: 1px solid $silver;
          grid-gap: 40px;
          padding-left: calc(50vw - 495.5px + 16px);
          padding-right: 16px;
          margin-left: calc(0px - (50vw - 495.5px + 16px));
          margin-right: calc(0px - (50vw - 495.5px + 16px));
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          &:first-child {
            display: none;
          }

          .header__nav-item {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            a {
              padding: 16px 0;
            }
          }
        }
      }

      &.scrolled {
        .header__nav {
          .header__nav-list {
            .header__nav-item {
              a {
                padding: 16px 0;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .header__wrapper {
      max-width: 720px;
    }

    .header__user {
      .header__user-menu {
        .header__user-menu-list {
          max-width: 688px;
        }
      }
    }

    &--is-auth {
      .header__nav {
        .header__nav-list {
          padding-left: calc(50vw - 360px + 16px);
          margin-left: calc(0px - (50vw - 360px + 16px));
          margin-right: calc(0px - (50vw - 360px + 16px));
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .header__wrapper {
      max-width: 575px;
    }

    .header__user {
      .header__user-menu {
        .header__user-menu-list {
          max-width: 543px;
        }
      }
    }
    &--is-auth {
      .header__nav {
        .header__nav-list {
          padding-left: calc(50vw - 287.5px + 16px);
          margin-left: calc(0px - (50vw - 287.5px + 16px));
          margin-right: calc(0px - (50vw - 287.5px + 16px));
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .header__wrapper {
      max-width: 100%;
    }

    .logo {
      max-width: 99px;
    }

    .header__auth {
      .header__auth-link {
        padding: 4px 16px;
        font-size: 1.2rem;
        line-height: 1.6rem;

        &.header__register {
          display: none;
        }
      }
    }

    .header__user {
      grid-gap: 8px;

      .header__user-icon {
        width: 24px;
        height: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .header__user-toggle {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .header__user-menu {
        top: 57px;
        padding: 21px 16px 26px;

        .header__user-menu-list {
          max-width: 100%;

          .header__user-menu-item {
            margin-bottom: 20px;

            a {
              grid-gap: 12px;
              font-size: 1.2rem;
              line-height: 2rem;

              svg {
                width: 17px;
                height: 17px;
              }
            }
          }
        }
      }
    }

    .header__menu-toggle {
      width: 24px;
      height: 24px;
      margin-left: 16px;
    }

    &--is-auth {
      .header__nav {
        .header__nav-list {
          grid-gap: 16px;
          padding-left: 16px;
          margin-left: -16px;
          margin-right: -16px;

          .header__nav-item {
            a {
              font-size: 1.2rem;
              line-height: 1.6rem;
            }
          }
        }
      }

      &.scrolled {
        .header__nav {
          .header__nav-list {
            grid-gap: 16px;
          }
        }
      }
    }
  }
}

.menu {
  position: fixed;
  z-index: 999;
  top: 104px;
  width: 100%;
  padding: 32px 0;
  background-color: $secondary-green;
  visibility: hidden;
  opacity: 0;

  .menu__list {
    .menu__item {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      a {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 700;
        color: $white;

        @media (hover) {
          &:hover {
            color: $secondary-black;
          }
        }
      }
    }
  }

  .header--is-auth & {
    top: 104px;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 1279px) {
    top: 74px;

    .header--is-auth & {
      top: 128px;
    }
  }

  @media screen and (max-width: 575px) {
    top: 58px;
    height: calc(100vh - 58px);

    .menu__list {
      .menu__item {
        margin-bottom: 20px;

        a {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }

    .header--is-auth & {
      top: 104px;
      height: calc(100vh - 104px);
    }
  }
}

.container {
  max-width: 1344px;
  padding: 0 16px;
  margin: 0 auto;

  @media screen and (max-width: 1680px) {
    max-width: 1280px;
  }

  @media screen and (max-width: 1439px) {
    max-width: 1199px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 991px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 720px;
  }

  @media screen and (max-width: 767px) {
    max-width: 575px;
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
