.backdrop {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(34, 34, 34, 0.12);
  visibility: hidden;
  opacity: 0;
}

.locked {
  .backdrop {
    visibility: visible;
    opacity: 1;
  }
}

.app {
  height: 100vh;
  .ant-layout {
    min-height: 100vh;
    background-color: $background-grey;
  }

  .ant-layout-content {
    max-width: 100vw;
    padding-top: var(--auth-indent);
    height: 100%;
  }

  th > div.ant-table-cell-fix-left-last {
    &::after {
      width: 0;
    }
  }

  &__content {
    margin: 0 64px;

    @media (max-width: 576px) {
      margin: 0 24px;
    }
  }

  &__text {
    &-large {
      font-size: 18px;
    }
  }
}
