.profile {
  &__container {
    color: $secondary-black;
    margin: 24px 0;

    .ant-divider {
      border-top-color: $primary-grey;
    }

    .ant-form-item-label {
      font-weight: 600;
      padding-bottom: 0;
    }

    a.ant-typography {
      color: $black;

      &:hover {
        color: $secondary-green;
      }
    }
  }

  &__header {
    h2.ant-typography {
      margin-bottom: 0;
    }
  }

  &__menu {
    .ant-card-body {
      padding: 32px;
    }
    .ant-divider {
      margin: 0;
    }

    &_item {
      svg {
        height: 24px;
        width: auto;
      }

      .ant-typography {
        font-size: 18px;
      }

      &:hover {
        cursor: pointer;

        .ant-typography {
          color: $secondary-green;
        }

        svg {
          color: $secondary-green;
        }
      }

      &-active {
        font-weight: 700;

        .ant-typography {
          width: 330px;
          height: auto;
          line-height: 24px;
          color: $secondary-green;
        }

        svg {
          color: $secondary-green;
        }
      }
    }
  }

  &__card {
    h3.ant-typography,
    h4.ant-typography {
      white-space: normal;
      margin-bottom: 0;
    }
    .ant-card-head {
      padding: 0;
    }
    .ant-card-head-title,
    .ant-card-body {
      padding: 40px 56px;
    }
    .ant-alert {
      padding: 24px 32px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
    .ant-alert-with-description .ant-alert-message {
      margin-bottom: 16px;
    }

    @media (max-width: 446px) {
      .ant-alert,
      .ant-card-head-title,
      .ant-card-body {
        padding: 24px;
      }
    }
  }

  &__avatar {
    .ant-space-compact-block {
      justify-content: center;
    }
    .ant-btn-icon-only {
      width: 40px;
      height: 40px;
    }
  }

  &__info {
    &-large {
      font-size: 16px;
    }
  }

  &__bill {
    h3.ant-typography,
    h4.ant-typography {
      margin-bottom: 0;
    }
    .ant-card-body {
      padding: 20px 24px;
    }
    .ant-divider {
      margin: 32px 0;
    }
  }

  &__unverified {
    .ant-alert {
      margin: 0 -64px;
      padding: 8px 64px;
    }
    .ant-alert-icon {
      margin-right: 16px;
    }
    a.ant-typography {
      color: $black;

      &:hover {
        color: $secondary-green;
      }
    }
  }

  &__blocked {
    &-ribbon {
      .ant-ribbon.ant-ribbon-placement-end {
        top: 0;
        right: -32px;
        @media (max-width: 451px) {
          height: fit-content;
          width: 50%;
          white-space: normal;
        }
      }
    }
    &-icon {
      margin-right: 8px;
      svg {
        color: $error;
      }
    }
    &-alert {
      width: 100%;
      .ant-alert {
        padding: 16px 24px;
      }
      .ant-alert-icon {
        margin-right: 16px;

        svg {
          width: 20px;
          height: auto;
        }
      }
    }
  }

  &__history {
    &-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__item {
    @media (max-width: 769px) {
      .ant-row {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .ant-col {
          display: block;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }

        .ant-col-sm-offset-1 {
          margin: 0;
        }
      }
    }
  }

  &__calc {
    &-btn {
      @media (min-width: 1620px) {
        .ant-btn {
          height: auto;
          line-height: 24px;
          padding: 16px;
        }
      }
    }
  }

  &__change-pass-btn {
    .ant-btn {
      height: auto;
      padding: 8px 16px;
      @media (min-width: 1620px) {
        height: auto;
        line-height: 24px;
        padding: 16px 32px;
      }
    }
  }
}
