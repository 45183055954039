@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --antd-wave-shadow-color: #00bc41;
}

html {
  font-size: 10px;
}

html.locked {
  padding-right: 0;
  overflow: hidden;
}
html.locked body {
  padding-right: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  background: #f8f8f8;
  color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #000;
}

a:hover {
  color: #00bc41;
  text-decoration: underline;
}

.ant-table-pagination.ant-pagination {
  margin: 0;
}

.ant-table-cell-ellipsis .ant-table-column-title {
  white-space: normal;
}

table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 2;
}

th div.ant-table-cell-fix-left {
  background: inherit;
}

.ant-btn-primary {
  background-color: #00bc41;
  border-color: #00bc41;
}

.ant-btn-primary:hover {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #00bc41;
  border-color: #00bc41;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  border-color: #00bc41;
}

.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-picker-today-btn,
.ant-picker-today-btn:hover,
.ant-picker-today-btn:active,
.ant-picker-header-view button:hover {
  color: #00bc41;
}

.ant-back-top {
  bottom: 90px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-filter-trigger.active svg {
  color: #00bc41;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
  border-color: #00bc41;
  color: #00bc41;
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a {
  color: #00bc41;
}
.ant-pagination-item:hover {
  border-color: #00bc41;
}

.ant-tooltip-inner,
.ant-tooltip-arrow-content::before {
  color: #000;
  background: #fff;
}

.ant-tooltip-inner {
  padding: 12px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after {
  border-bottom: 2px solid #00bc41;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item:focus,
.ant-menu-item-selected a,
.ant-menu-item-active,
.ant-menu-item a:hover,
.ant-menu-item a:active {
  color: #00bc41;
}

.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: #00bc41;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: transparent;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  color: transparent;
  border-bottom: 1px solid #d9d9d9;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:focus {
  background-color: #fff;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #fff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: 0 0 0.25px #000;
}

a.ant-typography:hover {
  color: #00bc41;
  text-decoration: underline;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #00bc41;
  border-color: #00bc41;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-title {
  border-color: #00bc41;
  color: #00bc41;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #00bc41;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00bc41;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #00bc41;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-weight: 700;
}

.ant-badge-count {
  background-color: #00bc41;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #00bc41;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00bc41;
}

.ant-card {
  border-radius: 12px;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input-number:hover,
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-picker:hover,
.ant-picker-focused,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customized-input) .ant-select-selector,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #00bc41;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #00bc41;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-radio-input:focus + .ant-radio-inner,
.ant-picker-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customized-input) .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(4 158 66 / 20%);
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: rgb(4 158 66 / 7%);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: rgb(4 158 66 / 15%);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after {
  border-top: 1px dashed rgb(4 158 66 / 50%);
  border-bottom: 1px dashed rgb(4 158 66 / 50%);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed rgb(4 158 66 / 50%);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed rgb(4 158 66 / 50%);
}

.ant-radio-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #00bc41;
}

.ant-form-large .ant-form-item-label > label {
  height: fit-content;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
.ant-input-suffix .anticon > svg,
.ant-picker-suffix .anticon > svg,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #00bc41;
}

@media (max-width: 576px) {
  .ant-card {
    padding: 0;
  }
}

@media (min-width: 1620px) {
  .ant-card {
    padding: 16px;
    font-size: 16px;
  }

  .ant-form-item-label > label,
  .ant-form-item > label {
    font-size: 16px;
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .ant-input-number-input {
    padding: 0;
  }

  .ant-input,
  .ant-input-number-lg,
  .ant-input-affix-wrapper {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }

  .ant-select-lg,
  .ant-picker-large .ant-picker-input > input {
    font-size: 16px;
    line-height: 24px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 16px;
  }

  .ant-picker-large,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px;
    height: 57px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
  }

  .ant-picker-range .ant-picker-clear,
  .ant-select-arrow {
    right: 16px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input,
  .ant-input-number-lg input {
    height: auto;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 24px;
  }

  .ant-cascader-menu {
    padding: 6px;
  }

  .ant-btn-lg {
    padding: 8px 16px;
    height: auto;
  }

  .ant-form label {
    font-size: 16px;
  }

  .ant-input-suffix .anticon > svg,
  .ant-picker-suffix > span > svg,
  .ant-picker-clear > span > svg {
    width: 18px;
    height: auto;
  }

  .ant-picker-panel-container {
    padding: 4px;
  }


}

.ant-slider:hover .ant-slider-track, .ant-slider-track, .ant-slider-dot-active {
  background-color: #00bc41;
}

.ant-slider-dot-active, .ant-slider-handle, .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open), .ant-slider-handle.ant-tooltip-open, .ant-slider-handle:focus {
  border-color: #00bc41;
}

.ant-slider-handle {
  width: 23px;
  height: 23px;
  top: -2px;
  border-color: #00bc41;
}

.ant-input-number-group .ant-input-number {
  border-right-color:  transparent;
  font-size: 27px;
  max-width: 260px;
}

.ant-input-number-group .ant-input-number input {
  text-align: right;
}

.ant-input-number-group .ant-input-number-focused, .ant-input-number-group .ant-input-number:hover {
  border-right-color:  #00bc41;
}

.ant-input-number-group-addon {
  background: transparent;
  font-size: 27px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-item-label {
  margin-bottom: 10px;
}

.ant-input-textarea-show-count:after {
  margin-top: 10px;
}