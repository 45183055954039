.registration {
  &__container {
    color: $secondary-black;
    margin: 24px 0;

    @media (min-width: 1620px) {
      h2.ant-typography {
        margin-bottom: 24px;
      }
    }
  }

  &__tabs {
    .ant-tabs-tab {
      font-size: 20px;
      line-height: 28px;
      padding: 12px 16px;
    }

    .ant-tabs-tab:hover,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $secondary-green;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      text-shadow: 0 0 0.25px $secondary-green;
    }

    .ant-tabs-ink-bar {
      background-color: $secondary-green;
    }

    .ant-card {
      margin-top: 16px;
    }
  }

  &__checkbox {
    margin-bottom: 24px;
  }

  &__button.ant-btn-primary {
    width: 100%;
    height: auto;
    padding: 12px 128px;

    @media (max-width: 510px) {
      padding: 12px 0;
    }
  }

  &__card {
    @media (min-width: 1620px) {
      .ant-tabs-top > .ant-tabs-nav {
        margin: 0 0 32px 0;
      }
    }
  }

  &__text {
    &-large {
      font-size: 20px;
    }
  }

  &__footer {
    .ant-space,
    .ant-space-item:not(.ant-space-item:last-of-type) {
      width: 100%;
      .ant-space {
        width: fit-content;
      }
    }
    .ant-typography {
      word-break: normal;
    }
    .ant-alert {
      padding: 12px 16px;
      width: 100%;
    }
    .ant-alert-icon {
      margin-right: 10px;
    }
  }
}
