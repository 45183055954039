.contract {
  margin: 24px 0;

  a.ant-typography {
    color: $black;
    &:hover {
      color: $secondary-green;
    }
  }

  &__header {
    h2.ant-typography {
      margin-bottom: 0;
    }
  }

  &__card {
    margin-bottom: 32px;
    h4.ant-typography {
      margin-bottom: 0;
    }

    .ant-collapse {
      background-color: $white;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: $white;
      padding: 0;
      font-size: 16px;
      text-decoration: underline;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
      flex: auto;
      width: 95%;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }

  &__badge {
    .ant-badge-count {
      background: $secondary-green;
      margin-left: 5px;
      box-shadow: none;
    }

    &-grey {
      .ant-badge-count {
        color: $secondary-black;
        background: $primary-grey;
        margin-left: 5px;
        box-shadow: none;
      }
    }
  }

  &__message {
    .ant-card-body {
      padding: 24px 0;
      background-color: $light-yellow;
      border: 1px solid $yellow;
    }

    &-text {
      margin-bottom: 16px;
    }
  }

  &__text-btn {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: $secondary-green;
    }
  }

  &__container {
    color: $secondary-black;
    margin: 24px 0;

    a.ant-typography {
      color: $black;

      &:hover {
        color: $secondary-green;
      }
    }
  }

  &__file-icon {
    svg {
      width: 16px;
      height: auto;
    }
  }

  &__header {
    h2.ant-typography {
      margin-bottom: 0;
    }
  }

  &__info {
    margin-right: 5px;

    &__margin {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;

      &-details {
        font-size: 28px;
      }
      &-icon {
        svg {
          color: $secondary-green;
        }
      }
    }

    &-subtitle {
      h5.ant-typography {
        margin-bottom: 0;
      }
    }

    &-small {
      font-size: 12px;
    }

    &-large {
      font-size: 16px;
    }

    &-xxl {
      font-size: 18px;
    }

    &-empty {
      margin-top: 24px;
    }

    &-adress {
      margin-right: 12px;
      font-size: 16px;
      line-height: 24px;
    }

    &-icon {
      margin-right: 10px;
    }

    &-copyable {
      svg {
        width: 14px;
        height: auto;
        color: $secondary-green;
      }
    }

    .ant-typography-expand,
    .ant-typography-edit,
    .ant-typography-copy {
      color: #00bc41 !important;
    }
  }

  &__report {
    .ant-alert {
      padding: 16px 24px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
    .ant-timeline-item {
      padding-bottom: 0;
    }
    .ant-timeline-item-content {
      margin: 0 0 16px 30px;
    }
    .ant-timeline-item-tail {
      height: 120%;
    }
    .ant-timeline-item-last > .ant-timeline-item-content {
      min-height: fit-content;
      margin: 0 0 0 30px;
    }

    &-title {
      font-size: 16px;
    }
  }

  &__message {
    .ant-card-body {
      padding: 24px 0;
      background-color: $light-yellow;
      border: 1px solid $yellow;
    }

    &-text {
      margin-bottom: 16px;
    }
  }

  &__breadcrumb {
    padding: 32px 0 20px;

    .ant-breadcrumb-link {
      cursor: pointer;

      &:hover {
        color: $secondary-green;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      font-weight: bold;
      margin: 0 22px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    color: $secondary-black;
  }

  &__bottom-btns {
    width: 100%;
    margin-bottom: 90px;
    margin-top: 20px;

    .ant-btn {
      margin-right: 20px;
    }
  }

  &__full-width {
    width: 100%;
  }
}

.ant-table-filter-dropdown {
  input {
    height: 44px;
  }

  .ant-btn {
    height: 44px;
    border-radius: 4px;
  }
}
