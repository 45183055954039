.footer {
  padding: 40px 0 50px;
  border-bottom: 16px solid #00bc41;
  background-color: #222222;
  margin-top: auto;

  &__nav-list {
    list-style-type: none;
    padding: 0;
  }

  .footer__wrapper {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1524px;
    padding: 0 16px;
    margin: 0 auto;
  }

  .logo {
    grid-row: 1;
    grid-column: 1;
    width: 164px;

    a {
      display: block;

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  h6 {
    margin-bottom: 12px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 700;
    color: rgba(#00bc41, 0.85);
  }

  .footer__nav {
    grid-row: 1/4;

    &--main {
      grid-column: 3;
      margin-left: 180px;
    }

    &--secondary {
      grid-row: 1/3;
    }

    .footer__nav-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      a {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 600;
        color: $white;

        @media (hover) {
          &:hover {
            color: #00bc41;
          }
        }
      }
    }
  }

  .footer__contacts {
    grid-row: 1;
    grid-column: 4;

    .footer__contacts-link {
      letter-spacing: -0.5px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 600;
      color: $white;

      @media (hover) {
        &:hover {
          color: #00bc41;
        }
      }
    }
  }

  .footer__socials {
    grid-row: 2;
    grid-column: 3;
    margin-top: 22px;

    .footer__socials-list {
      grid-gap: 24px;
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      height: 42px;
    }

    .footer__socials-item {
      flex-shrink: 0;

      a {
        display: block;

        svg {
          fill: $white;
        }

        @media (hover) {
          &:hover {
            svg {
              fill: #00bc41;
            }
          }
        }
      }
    }
  }

  .footer__license {
    grid-row: 1;
    grid-column: 2;
    opacity: 0.85;

    p {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      color: $light-grey;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer__policy {
    grid-column: 3;
    grid-row: 1;
    opacity: 0.85;

    a {
      display: block;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      color: $light-grey;

      &:last-child {
        margin-bottom: 0;
      }

      @media (hover) {
        &:hover {
          color: #00bc41 !important;
        }
      }
    }
  }

  .footer__nav {
    &--main {
      margin-left: 120px;
      margin-right: 150px;
    }

    &--secondary {
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 1680px) {
    .footer__wrapper {
      max-width: 1280px;
    }

    .footer__nav {
      &--main {
        margin-left: 0;
        margin-right: 120px;
      }
    }

    .footer__socials {
      margin-top: 34px;
    }

    .footer__license {
      max-width: 294px;

      p {
        color: rgba($white, 0.7);
      }
    }

    .footer__policy {
      a {
        color: rgba($white, 0.7);
      }
    }

    .footer__socials {
      margin-bottom: 24px;
    }

    .footer__policy {
      max-width: 294px;
    }
  }

  @media screen and (max-width: 1439px) {
    .footer__wrapper {
      max-width: 1199px;
    }
  }

  @media screen and (max-width: 1279px) {
    .footer__wrapper {
      max-width: 991px;
    }

    .footer__nav {
      &--main {
        margin-right: 25px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 48px 0;
    border-bottom-width: 12px;

    .footer__wrapper {
      grid-template-columns: repeat(3, auto);
      max-width: 720px;
    }

    .logo {
      max-width: 104px;
    }

    h6 {
      margin-bottom: 8px;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .footer__nav {
      &--main {
        grid-column: 3;
        margin-right: 40px;
      }

      &--secondary {
        grid-column: 3;
      }

      .footer__nav-item {
        margin-bottom: 8px;

        a {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }

    .footer__contacts {
      grid-column: 4;
      grid-row: 1;

      .footer__contacts-link {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    .footer__socials {
      grid-column: 3;
      margin-top: 32px;

      .footer__socials-list {
        grid-gap: 12px;
      }

      .footer__socials-item {
        a {
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    .footer__license {
      max-width: 152px;

      p {
        margin-bottom: 8px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $light-grey;
      }
    }

    .footer__policy {
      grid-column: 3;
      max-width: 243px;

      a {
        margin-bottom: 8px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $light-grey;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .footer__wrapper {
      max-width: 575px;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px 48px;
    }

    .footer__nav {
      &--main {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .footer__license {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .footer__contacts {
      grid-row: 1;
      grid-column: 2;
      margin-bottom: 16px;
    }

    .footer__license {
      grid-row: 2;
      grid-column: 1;
      max-width: 243px;
    }

    .footer__policy {
      grid-row: 2;
      grid-column: 2;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 40px 0 48px;
    border-bottom-width: 8px;

    .footer__wrapper {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      max-width: 100%;
    }

    .logo {
      grid-column: 1;
      grid-row: 1;
      max-width: 132px;
      margin-bottom: 16px;
    }

    h6 {
      margin-bottom: 12px;
      font-size: 1.2rem;
      line-height: 1.2rem;
      font-weight: 600;
    }

    .footer__nav {
      margin: 0;

      &--main {
        grid-row: 2;
        grid-column: 1;
      }

      &--secondary {
        grid-row: 2;
        grid-column: 2;
      }

      .footer__nav-item {
        margin-bottom: 12px;

        a {
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 500;
        }
      }
    }

    .footer__contacts {
      grid-row: 1;
      grid-column: 2;

      .footer__contacts-link {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
      }
    }

    .footer__socials {
      grid-row: 3;
      grid-column: 2;
      margin: 0;

      .footer__socials-item {
        a {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .footer__license {
      grid-row: 2;
      grid-column: 1;
      grid-column: 1;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 5px;
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }

    .footer__policy {
      grid-row: 2;
      grid-column: 2;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 5px;
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }
  }
}
