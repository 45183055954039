.settings {
  .ant-card {
    margin-bottom: 24px;

    &.settings__card {
      border-radius: 0;
    }
  }
  .ant-card-head {
    padding: 0 56px;
  }
  .ant-card-head-title {
    padding: 40px 0;
  }
  .ant-card-body {
    padding: 40px 56px;
  }
  &__tabs {
    .ant-tabs-tab:hover,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $secondary-green;
    }

    .ant-tabs-ink-bar {
      background-color: $secondary-green;
    }

    .ant-alert {
      padding: 16px 24px;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &__card {
    border: 1px solid $primary-grey;

    a.ant-typography {
      color: $secondary-green;
    }
    h3.ant-typography {
      white-space: normal;
      margin-bottom: 0;
    }
  }

  &__report {
    margin-bottom: 72px;

    span {
      font-size: 16px;
    }
  }

  &__item {
    @media (max-width: 769px) {
      .ant-row {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        .ant-col {
          display: block;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }

        .ant-col-sm-offset-1 {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 446px) {
    .ant-card-head,
    .ant-card-body {
      padding: 24px;
    }

    .ant-card-head-title {
      padding: 0;
    }
  }
}
