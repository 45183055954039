.modal {
  h3.ant-typography,
  h4.ant-typography {
    margin-bottom: 0;
  }
  .ant-modal-body {
    color: $black;
    padding: 32px;

    @media (min-width: 1620px) {
      font-size: 16px;
    }
  }
  .ant-modal-header {
    border-bottom: 0;
    padding: 24px 32px 0;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 0 32px 24px;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: $secondary-green;
    border-color: $secondary-green;
  }
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-treenode-selected {
    border-color: $secondary-green;
  }
  .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
    border: 1px solid $secondary-green;
  }
  .ant-tree.ant-tree-block-node .ant-tree-drop-indicator {
    background-color: $secondary-green;

    &::after {
      border-color: $secondary-green;
    }
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: $light-grey;
  }
  a.ant-typography {
    color: $black;
    &:hover {
      color: $secondary-green;
    }
  }
  .ant-tree {
    width: 100%;
    font-size: 16px;
    .ant-tree-treenode {
      display: flex;
      padding: 16px;
      border: 1px solid #d9d9d9;
      margin-bottom: 16px;
    }

    .ant-tree-treenode-draggable .ant-tree-draggable-icon {
      order: 1;
    }
  }
  .ant-tree-switcher {
    width: 0;
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    @media (max-width: 399px) {
      margin-left: 0;
    }
  }

  &__text {
    &-large {
      font-size: 16px;
    }
    &-cancel {
      font-size: 18px;
    }
  }
  &__close-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__cancel-btn {
    margin-right: 12px;
    @media (max-width: 376px) {
      justify-self: flex-end;
      order: 2;
      margin-right: 0;
      margin-top: 12px;
      width: fit-content;
    }
  }

  &__action-btn {
    @media (min-width: 1620px) {
      .ant-btn {
        height: auto;
        line-height: 24px;
        padding: 16px;
      }
    }
  }

  &__info {
    .ant-modal-body {
      display: flex;
      justify-content: center;
    }
    .ant-alert-icon {
      margin-right: 16px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  &__full-width {
    width: 100%;
  }

  &__scrollable {
    .ant-modal-header {
      border-bottom: 0;
      padding: 24px 32px 32px;
    }
    .ant-modal-body {
      padding: 0 32px;
    }
    .ant-modal-footer {
      padding: 32px 32px 24px;
      @media (max-width: 399px) {
        display: flex;
        justify-content: flex-end;
        .ant-row {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  &__table-settings {
    .ant-modal-footer {
      padding-top: 24px;
    }
  }

  &__org-info {
    .ant-modal-header {
      border-bottom: 1px solid #f0f0f0;
      padding: 42px 56px 32px;
    }
    .ant-modal-body {
      padding: 32px 56px 42px;
    }

    @media (max-width: 446px) {
      .ant-modal-header,
      .ant-modal-body {
        padding: 24px;
      }
    }
  }

  &__footer {
    @media (max-width: 376px) {
      display: flex;
      justify-content: flex-end;
      .ant-row {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

.avatar {
  .ant-modal-body {
    padding: 32px;
  }
  .ant-modal-header {
    border-bottom: 0;
    padding: 24px 32px 0;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 0 32px 24px;
  }
  div.ant-modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
  }
  .ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 16px;
    @media (max-width: 399px) {
      margin-left: 0;
    }
  }
  .ant-modal-close {
    top: 24px;
    right: 32px;
  }
  .ant-modal-close-x {
    width: 16px;
    height: auto;
    font-size: 16px;
    color: $black;
    line-height: 32px;
  }
}
