$primary-green: #049e42;
$secondary-green: #00bc41;

$primary-grey: #d9d9d9;
$secondary-grey: #767676;
$grey: #ccc;
$light-grey: #ececec;
$dark-grey: #5e5e5e;
$disabled-grey: #b8b8b8;
$background-grey: #f8f8f8;
$silver: #f0f0f0;

$white: #fff;
$black: #000;
$secondary-black: #222;

$warning: #ffa940;
$error: #ff4d4f;

$yellow: #ffe58f;
$light-yellow: #fffbe6;
