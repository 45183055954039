.toolbar {
  &__title {
    padding: 32px 0 24px 0;
    h2.ant-typography {
      margin-bottom: 0;
    }
    .ant-divider-horizontal {
      margin: 0;
    }

    @media (max-width: 713px) {
      gap: 14px;
    }
  }

  &__btns-panel {
    .ant-btn {
      margin-left: 16px;
    }

    .ant-dropdown-trigger {
      height: 40px;
      width: 140px;
      text-align: left;
    }

    .anticon-down {
      margin-left: 30px;
    }

    @media (max-width: 713px) {
      .ant-btn {
        margin-left: 0;
        margin-right: 16px;
      }
    }

    @media (max-width: 456px) {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
  }

  &__admin {
    &-title {
      padding: 16px 8px;
      border: 1px solid $primary-grey;
      border-bottom: none;

      h2.ant-typography {
        margin-bottom: 0;
      }

      .ant-col {
        display: flex;
        gap: 15px;
      }

      @media (max-width: 663px) {
        .ant-col {
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }

  &__btns {
    @media (min-width: 1620px) {
      .ant-btn-lg {
        height: auto;
        line-height: 24px;
        padding: 16px 32px;
      }
    }
  }
}

.ant-dropdown-menu-title-content {
  padding: 8px;
}
